<template>
  <div class="load-error-wrapper">
    <div class="load-error-content">
      <h3 class="load-error-title" v-if="isUnexpectedError">
        {{ $t("load_error.title") }}
      </h3>
      <div class="load-error-description" v-if="error == 'cafe_not_ready'">
        <div class="load-error-code" v-if="modeFormat">
          {{
            $t("load_error.mode_setup", {
              mode: modeFormat,
            })
          }}
        </div>
        <div class="load-error-code" v-else>
          {{ $t("load_error.wrong_setup") }}
        </div>
      </div>
      <div class="load-error-description" v-else-if="error == 'cafe_not_paid'">
        <div class="load-error-code">
          {{ $t("load_error.cafe_not_paid") }}
        </div>
      </div>
      <div class="load-error-description" v-else-if="error == 'not_found'">
        <div class="load-error-code">
          {{ $t("load_error.not_found") }}
        </div>
      </div>
      <div class="load-error-description" v-else-if="error == 'request_failed'">
        <div class="load-error-code">
          {{ $t("load_error.request_failed") }}
        </div>
      </div>
      <div class="load-error-description" v-else>
        {{ $t("load_error.code") }}
        <span class="load-error-code">{{ error }}</span>
      </div>
      <a href="https://app.qrwaiter.com.ua" v-if="isFromMarketplace">
        <button class="load-error-button">
          {{ $t("load_error.back") }}
        </button>
      </a>
      <button class="load-support-button" @click="bootIntercom">
          {{ $t("load_error.support") }}
        </button>
    </div>
    <div class="load-error-footer">
      <!-- <div class="load-error-panda">
          <img src="../assets/error-hero.svg" />
        </div>
        <span class="load-error-support">
          Telegram Support <a href="https://t.me/QRWaiter">@QRWaiter</a>
        </span> -->
    </div>
  </div>
</template>

<script>
import MarketplaceService from "@/services/marketplace"

export default {
  props: ["error"],
  mounted() {
    this.toggleGlobalScrolling(true);
  },
  beforeDestroy() {
    this.toggleGlobalScrolling(false);
  },
  methods: {
    bootIntercom() {
      window.Intercom("boot", {
        app_id: "rsh7gloh",
        hide_default_launcher: true
      });

      window.Intercom('show');
    },
  },
  computed: {
    modeFormat() {
      if (this.$route.name == "new_order_code") return "INSIDE";
      if (this.$route.name == "delivery") return "DELIVERY";
      if (this.$route.name == "preorder") return "TAKEAWAY";

      return null;
    },
    isFromMarketplace() {
      return MarketplaceService.get() && MarketplaceService.get().flag;
    },
    isUnexpectedError() {
      return ![
        "cafe_not_paid",
        "cafe_not_ready",
        "not_found",
        "request_failed",
      ].includes(this.error);
    },
  },
};
</script>

<style scoped>
.load-error-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  z-index: 333;

  background: #edededed;
}

.load-error-content {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 90%;
  height: auto;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}

.load-error-code {
  color: #e2223b;
}

.load-error-description {
  font-size: 2vh;
}

.load-error-title {
  color: #000000;
  font-size: 2.8vh;

  margin-top: 5vh;
}

.load-error-button {
  width: 90%;
  padding: 1.6vh 2vw;
  margin: 3.7vh auto 0;
  display: block;
  border: 0px;
  background: #129b81;
  box-shadow: 0px 4px 1px rgba(0, 0, 0, 0.25);
  border-radius: 10vw;
  font-size: 4.5vw;
  font-weight: 500;
  outline: none;
  color: white;

  margin-top: 10vh;
}

.load-support-button {
  width: 90%;
  padding: 1.6vh 2vw;
  margin: 3.7vh auto 0;
  display: block;
  border: 1px solid #129b81;
  color: #129b81;
  border-radius: 10vw;
  font-size: 4.5vw;
  font-weight: 500;
  outline: none;
  margin-top: 3.7vh;
}

.load-error-footer {
  position: fixed;
  bottom: 5vh;
  width: 100%;
  text-align: center;
}

.load-error-support {
  color: #a4a3a1;
}

@media screen and (min-width: 768px) {
  .load-error-button, .load-support-button {
    font-size: 2.3vh;
    width: 50%;
  }
}
</style>