import BaseAPI from '../base'

function activateCafe(code) {
    return BaseAPI.sendPost(`join/activate`, {
        code
    });
}

export default {
    activateCafe
}