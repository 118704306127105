<template>
  <div class="activation-wrapper">
      <LoadError :error="error" v-if="error"/>
      <OrderLoader v-if="busy && !error"/>

      <div class="activation-success" v-if="!busy && !error">
          <!-- <img src="/hero-pics/eat.svg" class="panda"> -->
          <img :src="cafe_logo" class="panda" v-if="cafe_logo">
          <h3 class="color-green">{{ $t('activate_cafe.title', {
            cafe_name: !cafe_logo ? cafe_name : ''
           }) }}</h3>
          <p>
             {{ $t('activate_cafe.text') }}
          </p>
          <LongButton variant="success" @click="goToPayment" v-if="subscribe_form">
            {{ $t('activate_cafe.subscribe') }}
            </LongButton>
      </div>

    <form
      method="POST"
      action="https://www.liqpay.ua/api/3/checkout"
      accept-charset="utf-8"
      ref="subscribeForm"
      v-if="subscribe_form"
    >
      <input type="hidden" name="data" :value="subscribe_form.data" />
      <input type="hidden" name="signature" :value="subscribe_form.signature" />
    </form>
  </div>
</template>

<script>
import OrderLoader from "@/components/OrderLoader.vue";
import LoadError from "@/components/LoadError.vue";

import LongButton from "@/components/LongButton.vue"

import JoinAPI from "@/api/join"

export default {
  data() {
    return {
      busy: true,
      error: null,
      cafe_logo: null,
      cafe_name: null,
      subscribe_form: null
    };
  },
  methods: {
      checkCode() {
          JoinAPI.activateCafe(this.$route.params.code).then(data => {
              this.cafe_logo = data.cafe_logo;
              this.cafe_name = data.cafe_name;
              this.subscribe_form = data.subscribe_form;
              this.busy = false;
          }).catch(error => {
              this.error = error;
          });
      },
      goToPayment() {
        if (this.$refs.subscribeForm) {
          this.$refs.subscribeForm.submit();
        }
      }
  },
  mounted() {
    this.checkCode();
  },
  components: {
    OrderLoader,
    LoadError,
    LongButton
  }
};
</script>

<style scoped>
.activation-wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: #1d1d1b;
}

.activation-success {
    text-align: center;
    color: #ededed;

    /* margin-top: 10vh; */
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;

    padding: 1vh 2vh;
    height: fit-content;
}

h3 {
    font-size: 3.2vh;
}

.panda {
    height: 15vh;
    margin-bottom: 1vh;
    border-radius: 1vh;
}
</style>